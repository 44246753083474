import React, { useEffect } from 'react'
import AOS from 'aos'
import { DEVELOPER_URL, FORM_URL } from "../../static/constants/Config"

const FormCard = ({title}) => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    const styleToBeUsed = title === "INICIO" ? "heroIndexScreen" : "heroContactoScreen"

    return (
        <div className={styleToBeUsed}>
            <section className="container">
                <form className="col-sm text-white fadeInDown" id="contactform" action={FORM_URL}>
                    <input name="_redirect" type="hidden" id="name" value={DEVELOPER_URL + '/thanks'} />
                    <input name="_formsubmit_id" type="text" className="noDisplayElement" />
                    <h2>
                        DEJANOS TUS DUDAS O COMENTARIOS
                    </h2>
                    <div className="group">
                        <div className="row">
                            <div className="col-sm">
                                <label htmlFor="inputName" className="form-label">Nombre</label>
                                <input type="text" name="name" className="form-control" id="inputFirstName"
                                    placeholder="Agrega tu Nombre" required />
                            </div>
                            <div className="col-sm">
                                <label htmlFor="inputLastName" className="form-label">Apellido</label>
                                <input type="text" name="lastName" className="form-control" id="inputLastName"
                                    placeholder="Agrega tu Apellido" required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputEmail" className="form-label">Correo Electrónico</label>
                                <input type="email" name="email" className="form-control" id="inputEmail"
                                    placeholder="Agrega tu Correo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="inputPhone" className="form-label">Teléfono</label>
                                <input type="phone" name="phone" className="form-control" id="inputPhone" required />
                            </div>
                            <div className="col-12">
                                <label htmlFor="inputMessage" className="form-label">Mensaje</label>
                                <textarea className="form-control" name="message" id="inputMessage" rows="3"
                                    required></textarea>
                            </div>
                            <div className="col-12 pt-3 pb-5">
                                <button type="submit" className="btn mpk_button">Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default FormCard