import React, { useEffect } from 'react'
import AOS from 'aos'

const AboutCards = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    return(

        <section className="container" id="descriptionSection">
            <div className="row" data-aos="flip-up">
                <div className="row">
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <p className="card-header">
                            Historia
                        </p>
                        <p className="card-text">
                        Desde nuestro inicio el 1 de mayo de 2023, hemos sido un equipo multidisciplinario comprometido con brindar atención personalizada y de alta calidad.
                                    Nos especializamos en diversas áreas de la ingeniería, adaptando nuestras soluciones a las necesidades específicas de cada cliente.
                                    Nos gusta trabajar en equipo y nos esforzamos por ser un referente en el mercado de la tecnología..
                        </p>
                        <i className="fa-regular fa-address-card fa-2x pb-2"></i>
                    </div>
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <p className="card-header">
                            Misión
                        </p>
                        <p className="card-text">
                        Nuestra misión es ofrecer servicios confiables y eficientes que superen las expectativas.
                                    Facilitar el acceso a la tecnología a un precio justo, proporcionando soluciones sencillas y
                                    efectivas para aquellos que no están familiarizados con dispositivos como computadoras, multifuncionales y celulares.
                                    Nos comprometemos a minimizar las complicaciones y hacer que la tecnología sea mas accesible para todos.
                        </p>
                        <i className="fa-solid fa-globe fa-2x pb-2"></i>
                    </div>
                    <div className="card col-sm text-center pt-3 border-0">
                        <p className="card-header">
                        Visión
                        </p>
                        <p className="card-text">
                        Ser la empresa líder en soluciones tecnológicas accesibles y fáciles de usar,
                                    reconocida por nuestra dedicación a la calidad, la innovación y el servicio al cliente.
                                    Aspiramos a crear un mundo donde la tecnología esté al alcance de todos, sin barreras ni complicaciones
                        </p>
                        <i className="fa-solid fa-circle-plus fa-2x pb-2"></i>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default AboutCards