import React, { useEffect } from 'react'
import AOS from 'aos'
import Card from 'react-bootstrap/Card'

const ThreeCards = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    const imageMap = {
        "Transparencia": require('../../static/images/nosotros/transparencia.jpg'),
        "Adaptabilidad": require('../../static/images/nosotros/adaptabilidad.jpg'),
        "Calidad": require('../../static/images/nosotros/calidad.jpg'),
        "Buen Servicio": require('../../static/images/nosotros/buen_servicio.jpg'),
        "Honradez": require('../../static/images/nosotros/honradez.jpg'),
    }

    return (
        <div className="bg_dark pb-3" data-aos="fade-down-left">
            <section className="container three_cards">
                <h2> Valores <i className="fa-brands fa-slideshare"></i> </h2>
                <div className="row m-auto left_card" data-aos="flip-up">
                    <h3>Transparencia</h3>
                    <div className="col-8">
                        Nos comprometemos a ser claros y honestos en
                        todas nuestras interacciones, asegurando que nuestros
                        clientes siempre sepan exactamente lo que están obteniendo.
                    </div>
                    <div className="col-4">
                        <Card.Img className="image_border" variant="top" src={imageMap["Transparencia"]}></Card.Img>
                    </div>
                </div>
                <div className="row m-auto right_Card" data-aos="flip-down">
                    <h3>Adaptabilidad</h3>
                    <div className="col-4">
                        <Card.Img className="image_border" variant="top" src={imageMap["Adaptabilidad"]}></Card.Img>
                    </div>
                    <div className="col-8">
                        Ofrecemos soluciones personalizadas que se ajustan a las necesidades específicas de cada cliente,
                        garantizando que reciban exactamente lo que necesitan.
                    </div>
                </div>
                <div className="row m-auto left_card pt-5" data-aos="flip-left">
                    <h3>Calidad</h3>
                    <div className="col-8">
                        Mantenemos los más altos estándares en todos nuestros productos y servicios,
                        asegurando que cada solución que ofrecemos sea de la mejor calidad posible.
                    </div>
                    <div className="col-4">
                        <Card.Img className="image_border" variant="top" src={imageMap["Calidad"]}></Card.Img>
                    </div>
                </div>
                <div className="row m-auto right_Card" data-aos="flip-right">
                    <h3>Buen Servicio</h3>
                    <div className="col-4">
                        <Card.Img className="image_border" variant="top" src={imageMap["Buen Servicio"]}></Card.Img>
                    </div>
                    <div className="col-8">
                        Nos esforzamos por proporcionar una atención al cliente excepcional,
                        escuchando y respondiendo a las necesidades y preocupaciones de nuestros clientes de manera rápida y eficiente.
                    </div>
                </div>
                <div className="row m-auto left_card pt-5" data-aos="zoom_in">
                    <h3>Honradez</h3>
                    <div className="col-8">
                        Actuamos con integridad en todas nuestras operaciones,
                        manteniendo un comportamiento ético y responsable en todo momento.
                    </div>
                    <div className="col-4">
                        <Card.Img className="image_border" variant="top" src={imageMap["Honradez"]}></Card.Img>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ThreeCards