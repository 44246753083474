import React, { useEffect } from 'react'
import AOS from 'aos'
import { FACEBOOK_URL, FACEBOOK_MESSENGER_URL, INSTAGRAM_URL, WHATSAPP_URL, GOOGLE_MAP_URL } from '../../static/constants/Config'

const AddressCard = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    const styleFrame = {
        border: "0",
    }

    return (
        <div className="bg_dark" data-aos="fade-right">
            <section className="container border border-0 bg_dark mpk_link_color">
                <div className="row">
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <iframe id="idGoogleMap" className="frame_responsive pt-3"
                            src={GOOGLE_MAP_URL}
                            style={styleFrame} allowFullScreen="" loading="lazy"
                            title="map"></iframe>
                    </div>
                    <div className="card col-sm text-center text-white pt-3 pb-3 border-0">
                        <b className="pt-3">DIRECCION</b>
                        <p>
                            Av. 20 de Noviembre #214 Ote. Local #29
                            Zona Centro, Plaza de la Tecnologia
                            Durango, Durango 34000
                        </p>
                        <b>CONTACTO</b>
                        <p><i className="fa-solid fa-at email_icon"></i> mipekicha-digital@mipekicha.com</p>
                        <p><i className="fa-solid fa-at email_icon"></i> marketing@mipekicha.com</p>
                        <p>(61) 83-65-8753 - DGO</p>
                        <b>Horario de Atencion</b>
                        <p>Lunes a Viernes de 10:00 am - 18:00 pm</p>
                    </div>
                    <div className="card col-sm text-center text-white pt-3 pb-3 border-0">
                        <b className="pt-3">REDES</b>
                        <span><a href={FACEBOOK_URL} rel="noreferrer" target="_blank" className="p-1">Facebook</a><i
                            className="fab fa-facebook-f facebook_icon"></i></span>
                        <span><a href={INSTAGRAM_URL} rel="noreferrer" target="_blank" className="p-1">Instagram</a><i
                            className="fab fa-instagram instagram_icon"></i></span>
                        <span><a href={WHATSAPP_URL} rel="noreferrer" target="_blank" className="p-1">WhatsApp</a><i
                            className="fa-brands fa-whatsapp whats_app_icon"></i></span>
                        <span><a href={FACEBOOK_MESSENGER_URL} rel="noreferrer" target="_blank" className="p-1">Messenger</a><i
                            className="fa-brands fa-facebook-messenger"></i></span>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AddressCard